import React from 'react';
import { StyleSheet, SafeAreaView, View } from 'react-native';
import { Text } from '@rugby-au/commons';
import { WebHeader } from '@rugby-au/web-header';
import { useAppConfig } from '@rugby-au/app-config';
import { Headings } from '@rugby-au/headings';
import { ISpacing, useTheme } from '@rugby-au/theme';
import { Container } from '@rugby-au/container';
import { Button } from '@rugby-au/button';
import { getItem } from '@rugby-au/business';

const Custom404 = () => {
  const { header, setNavigation } = useAppConfig();
  const { spacing } = useTheme();

  const styles = getStyles({ spacing });

  return (
    <SafeAreaView>
      <WebHeader {...header} />
      <Container>
        <View style={styles.container}>
          <Headings title={'404'} level={'1'} />
          <Headings title={"Sorry, we can't find the page you asked for."} level={'3'} />
          <Text style={{ marginVertical: spacing.large }}>Sorry this page has not been found, please check the URL for errors.</Text>
          <Button
            weight={'underline'}
            title={'Click here to return to the home page.'}
            onPress={async () => {
              const client = await getItem('client');
              let redirectUrl = 'https://myaccount.rugbyxplorer.com.au';
              if (client && client.redirectUrl?.trim() !== '') {
                redirectUrl = client.redirectUrl?.trim();
              }
              setNavigation && setNavigation(redirectUrl);
            }}
          />
        </View>
      </Container>
    </SafeAreaView>
  );
};

const getStyles = ({ spacing }: { spacing: ISpacing }) => StyleSheet.create({ container: { flex: 1, marginVertical: spacing['2xlarge'] } });

export default Custom404;
